import { ApolloProvider as ApolloProviderRaw } from '@apollo/client'
import type {
  CreateApolloClientOptions} from '@dreamstack/artemis';
import {
  usePreviewSecret,
} from '@dreamstack/artemis'
import { useLocale } from '@dreamstack/i18n'
import { useApollo } from '@dreamstack/investors-graphql'
import type { FunctionComponent} from 'react';
import React, { useMemo } from 'react'

export const ApolloProvider: FunctionComponent<React.PropsWithChildren<{ pageProps: any }>> = ({
  pageProps,
  children,
}) => {
  const locale = useLocale()
  const previewModeSecret = usePreviewSecret()

  const { initialApolloState } = pageProps
  const apolloOptions = useMemo(
    () =>
      ({
        initialState: initialApolloState,
        locale,
        previewModeSecret,
        ssrMode: false,
      } as CreateApolloClientOptions),
    [initialApolloState, locale, previewModeSecret]
  )

  const apolloClient = useApollo(apolloOptions)

  return (
    <>
      <ApolloProviderRaw client={apolloClient}>{children}</ApolloProviderRaw>
    </>
  )
}

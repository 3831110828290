export const ALGOLIA_APP_ID = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID!
export const ALGOLIA_API_KEY_SEARCH =
  process.env.NEXT_PUBLIC_ALGOLIA_API_KEY_SEARCH!
export const ALGOLIA_API_KEY_ADMIN = process.env.ALGOLIA_API_KEY_ADMIN!
export const ALGOLIA_INDEX_NAME = process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME!

export const ALGOLIA_UPDATE_SECRET = process.env.ALGOLIA_UPDATE_SECRET
export const NEXT_PUBLIC_USERCENTRICS_ID =
  process.env.NEXT_PUBLIC_USERCENTRICS_ID || 'jWtoeXLkA'
export const NEXT_PUBLIC_GOOGLE_ANALYTICS_ID =
  process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID || 'G-H8GXK0LXEN'
export const NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID =
  process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID || 'GTM-MQPTMDT'

import '@dreamstack/datadog/init' // Initialize datadog with side-effects
import { ExitPreview } from '@dreamstack/feature-components'
import { trpc } from '@dreamstack/trpc/client'
import { SpeedInsights } from '@vercel/speed-insights/react'
import 'keen-slider/keen-slider.min.css'
import { appWithTranslation } from 'next-i18next'
import type { AppProps } from 'next/app'
import type { AppContextType } from 'next/dist/shared/lib/utils'
import { MainProvider } from '../lib/provider/MainProvider'
import '../public/fonts/fontfaces.css'

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <MainProvider pageProps={pageProps}>
      <ExitPreview />
      <Component {...pageProps} />
      <SpeedInsights />
    </MainProvider>
  )
}

MyApp.getInitialProps = async (appContext: AppContextType) => {
  const context = appContext.ctx as any
  if (appContext.router.isPreview) {
    context.HACKY_IS_PREVIEW = true
  }
  return {}
}

export default trpc.withTRPC(appWithTranslation(MyApp))

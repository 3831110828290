import type {
  CreateApolloClientSsrOptions} from '@dreamstack/artemis';
import {
  createApolloClientRawSsr
} from '@dreamstack/artemis'
import { useMemo } from 'react'
import { InvestorsApolloOptions } from './ApolloOptions'

export const createApolloClientSsr = (
  options: CreateApolloClientSsrOptions
) => {
  return createApolloClientRawSsr({
    ...InvestorsApolloOptions,
    ...options,
  })
}

export const useApollo = (options: CreateApolloClientSsrOptions) =>
  useMemo(() => createApolloClientSsr(options), [options])

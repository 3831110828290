import type { GraphQLRequest } from '@apollo/client'
import type { CreateApolloClientOptions } from '@dreamstack/artemis'
import { possibleTypes } from './possibleTypes'

const shouldUseHasura = (operation: GraphQLRequest) => {
  return operation.operationName === 'GetAutocompleteCities'
}

export const InvestorsApolloOptions: Partial<CreateApolloClientOptions> = {
  possibleTypes,

  graphqlEndpointUrl: (operation) => {
    return shouldUseHasura(operation)
      ? process.env.NEXT_PUBLIC_GRAPHQL_API! // Hasura
      : process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT_URL! // Contentful
  },

  getAuthHeaders: async (options, headers, operation) => {
    // Don't include an "Authorization" header when using Hasura
    if (shouldUseHasura(operation)) return headers

    const { previewModeSecret } = options
    const token = !!previewModeSecret
      ? process.env.CONTENTFUL_API_TOKEN_PREVIEW
      : process.env.CONTENTFUL_API_TOKEN_PUBLIC

    headers['Authorization'] = `Bearer ${token}`
    return headers
  },
}
